/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: transparent;
  border: 2px solid ${props => props.white ? props.theme.color.light : props.theme.color.main};
  display: inline-block;
  transition: 0.25s;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.main};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const ButtonLine = ({ white, isAnchor, isCustom, to, children, className }) => (
  <StyledButton white={white} className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonLine