/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Template
import Home from '../templates/home'

export const pageQuery = graphql`
  {
    page: wordpressPage(
        wordpress_id: { eq: 2 }
      ) {
        ...HomeFrag
    }
  }
`

const HomeTemplate = ({ data }) => {
  return <Home data={data} />
}

export default HomeTemplate