import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ButtonLine from 'components/elements/ButtonLine'
import TitleDefault from 'components/elements/TitleDefault'
import ThreeFishPlates from 'components/shared/ThreeFishPlates'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

export const pageQuery = graphql`
  {
    page: wordpressPage(
        wordpress_id: { eq: 2 }
      ) {
        ...HomeFrag
    }
  }
`

const StyledImg = styled(Img)``

const FirstContent = styled.div`
  margin-top: 100px;

  h1 {
    width: 85%;
    line-height: 75px;
    font-size: ${props => props.theme.font.size.xxxxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxxl};
      line-height: 55px;
      width: 100%;
      text-align: center;
    }
  }

  ${StyledImg} {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`

const MarketAssort = styled.div`
  margin-bottom: 100px;
  margin-top: 100px; 

  ${StyledImg} {
    height: 374px;
  }

  @media screen and (max-width: 991px) {
    ${StyledImg} {
      height: 350px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 0px;

    ${StyledImg} {
      height: 300px;
    }
  }

  @media screen and (max-width: 430px) {
    ${StyledImg} {
      height: 200px;
    }
  }

  h2 {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${props => props.theme.color.light};
    }
  }
`

const OurFish = styled.div`
  p {
    line-height: 40px;

    @media screen and (max-width: 991px) {
      line-height: 30px;
    }
  }

  h2 {
    font-size: ${props => props.theme.font.size.xxxxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxxl};
      text-align: center;
    }
  }
`

const BackgroundImg = styled.img`
  position: absolute;
  left: -300px;
  top: -100px;

  @media screen and (max-width: 991px) {
    opacity: 0.3;
  }
`

const FishPlates = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxxxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxxl};
      text-align: center;
    }
  }
`

const HomeTemplate = ({
  data: {
    page: { 
      path,
      acf: {
        first_content: firstContent,
        markets_group: {
          market_group: marketGroup,
          assortiment_group: assortimentGroup
        },
        our_fish_group: ourFishGroup,
        our_fishplates: ourFishplates
      },
      yoastMeta 
    },
  }
}) => {
  return (
    <Layout big>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <div className="container py-5">

        <FirstContent className="row pb-5">
          <div className="col-lg-6">
            <TitleDefault h1 className="pb-5 mb-0">{firstContent.text}</TitleDefault>
            <div className="d-flex justify-content-center">
              <ButtonLine to={firstContent.link.url}>
                <p className="mb-0 font-size-m">{firstContent.link.title}</p>
              </ButtonLine>
            </div>
          </div>
          <div className="col-lg-6 pt-lg-5 mt-lg-5">
            <StyledImg fluid={firstContent.photo.localFile.childImageSharp.fluid} alt="" />
          </div>
        </FirstContent>

        <MarketAssort className="row py-5">
          <div className="col-lg-6 pb-5 pb-lg-0">
            <div className="row">
              <div className="col-md-6 pr-lg-1">
                <StyledImg fluid={marketGroup.image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-md-6 pl-lg-1 ">
                <div className="h-100 flex-column d-flex justify-content-between color-background-main px-3 py-4">
                  <div>
                    <h2 className="mb-3 color-text-light font-family-secondary font-size-xxml">{marketGroup.title}</h2>
                    <p className="font-size-m color-text-light">{parse(marketGroup.text)}</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <ButtonLine white to={marketGroup.link.url}>
                      <p className="mb-0 font-size-m color-text-light">{marketGroup.link.title}</p>
                    </ButtonLine>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-6 pr-lg-1">
                <StyledImg fluid={assortimentGroup.image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-md-6 pl-lg-1">
                <div className="h-100 flex-column d-flex justify-content-between color-background-contrast px-3 py-4">
                  <div>
                    <h2 className="mb-3 color-text-light font-family-secondary font-size-xxml">{assortimentGroup.title}</h2>
                    <p className="font-size-m color-text-light">{parse(assortimentGroup.text)}</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <ButtonLine white to={assortimentGroup.link.url}>
                      <p className="mb-0 font-size-m color-text-light">{assortimentGroup.link.title}</p>
                    </ButtonLine>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MarketAssort>

        <OurFish className="row py-5">
          <div className="col-lg-6">
            <BackgroundImg src={ourFishGroup.background_image.localFile.publicURL} alt="" />
          </div>
          <div className="col-lg-6">
            <h2 className="font-family-secondary">{ourFishGroup.title}</h2>
            {parse(ourFishGroup.text)}
          </div>
        </OurFish>

        <FishPlates className="pt-lg-5 pt-3">
          <h2 className="font-family-secondary">{ourFishplates.title}</h2>
        </FishPlates>

        <ThreeFishPlates className="d-flex pb-lg-5 pb-3" />

        <FishPlates className="py-lg-5 py-3">
          <div className="d-flex justify-content-center">
            <ButtonLine to={ourFishplates.link.url}>
              <p className="mb-0 font-size-m">{ourFishplates.link.title}</p>
            </ButtonLine>
          </div>
        </FishPlates>

      </div>
    </Layout>
  )
}

export default HomeTemplate